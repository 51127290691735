<template>
    <div
        class="Sale priceSetting"
        v-loading="loading"
    >
        <div class="header_Sale">
            <span class="el_left">生效时间</span>
            <div class="block el_left">
                <el-date-picker
                    v-model="timeValue"
                    type="datetime"
                    placeholder="选择日期时间"
                    align="right"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :picker-options="pickerOptions"
                    @change="preserveBasicInfo('timeValue')"
                    :disabled="isRelease !== 0"
                >
                </el-date-picker>
            </div>
            <el-button
                class="el_left"
                type="primary"
                v-if="isRelease == 0"
                @click="release"
            >
                发布
            </el-button>
            <el-select
                class="el_left"
                v-model="historyId"
                placeholder="请选择"
                @change="selectionPeriod"
            >
                <el-option
                    v-for="item in history"
                    :key="item.period"
                    :label="item.period_str"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            <el-button
                type="primary"
                :loading="buttonLoading"
                class="el_left"
                v-if="isRelease == 1"
                @click="generateNewPeriods"
            >
                生成新期数
            </el-button>
            <el-button
                type="primary"
                class="el_left"
                v-if="isRelease == 2"
                @click="returnLatest"
            >
                返回最新一期
            </el-button>
        </div>
        <div class="basePrice">
            <!-- <div class="historicalPeriodMask" v-if="isRelease != 0 && !isRegion && !releaseAlsoLook"></div> -->
            <div class="el_left basePrice_l">
                <div class="isReleaseMask" v-if="isRelease != 0 && releaseAlsoLook"></div>
                <p class="head">
                    企业基准价（元）
                </p>
                <ul v-if="initData.SalePriceInfo">
                    <li>
                        <p class="title">
                            统一售价
                        </p>
                        <p class="type">
                            C30
                        </p>
                        <div class="price">
                            <input
                                type="text"
                                v-model="initData.SalePriceInfo.price_c30"
                                @change="preserveBasicInfo()"
                            >
                        </div>
                    </li>
                    <li>
                        <p class="title">
                            最低限价
                        </p>
                        <p class="type">
                            C30
                        </p>
                        <div class="price">
                            <input
                                type="text"
                                v-model="initData.SalePriceInfo.price_c30_min"
                                @change="preserveBasicInfo()"
                            >
                        </div>
                    </li>
                    <li>
                        <p class="title">
                            统一售价
                        </p>
                        <p class="type">
                            M5
                        </p>
                        <div class="price">
                            <input
                                type="text"
                                v-model="initData.SalePriceInfo.price_M5"
                                @change="preserveBasicInfo()"
                            >
                        </div>
                    </li>
                    <li>
                        <p class="title">
                            最低限价
                        </p>
                        <p class="type">
                            M5
                        </p>
                        <div class="price">
                            <input
                                type="text"
                                v-model="initData.SalePriceInfo.price_M5_min"
                                @change="preserveBasicInfo()"
                            >
                        </div>
                    </li>
                </ul>
            </div>
            <div class="el_right basePrice_r">
                <div class="basePriceBtns">
                    <el-button
                        class="el_right"
                        type="primary"
                        size="mini"
                        :disabled="isRelease !== 0 ? true : false"
                        @click="regionalManagement"
                    >
                        区域管理
                    </el-button>
                </div>
                <div :class="{'active': isRegion}" class="selectionArea el_left">
                    <!-- <div class="isSelectionArea" v-if="isRelease != 0 && isRegion"></div> -->
                    <label @click="selectionArea">
                        <i class="iconfont iconfuxuankuang checkbox_off"></i>
                        <i class="iconfont iconfuxuankuangxuanzhong checkbox_on"></i>
                        按区域设定
                    </label>
                </div>
                <div class="region el_right">
                    <region
                        :is-region="isRegion"
                        :is-release="isRelease"
                        :region-data="regionData"
                        @selectionAreaCallback="selectionAreaCallback"
                    ></region>
                </div>
            </div>
        </div>
        <div class="sort">
            <ul class="el_left">
                <template v-for="(item, index) in tabData">
                    <li :class="{'active': tabActive == index }" @click="tabChange(item.id, index)" :key="index">
                        <i class="iconfont iconshengjiang" :class="item.className"></i>
                        {{ item.name }}
                    </li>
                </template>
            </ul>
            <div class="tables">
                <div class="historicalPeriodMask" v-if="isRelease != 0"></div>
                <component
                    :is="currentView"
                    :is-release="isRelease"
                    :current-data="{historyId, areaid, initData, isRegion, releaseAlsoLook, selectRow}"
                    @pumpingCostCallback="pumpingCostCallback"
                    @modifyPrice="modifyPrice"
                ></component>
            </div>
        </div>
    </div>
</template>

<script>
// 销售通用样式
import './css/saleStyle.styl';
// 按区域设定table
import region from './enterprisePriceSettings/region.vue';
// 升降标号页
import grade from './enterprisePriceSettings/grade.vue';
// 附加型号页
import additional from './enterprisePriceSettings/additional.vue';
// 泵送费页
import pumpingCost from './enterprisePriceSettings/pumpingCost.vue';
// 运费页
import freight from './enterprisePriceSettings/freight.vue';
// 承兑页
import acceptance from './enterprisePriceSettings/acceptance.vue';

export default {
    // 销售价格管理
    name: 'price-setting',
    components: {
        region,
        grade,
        additional,
        pumpingCost,
        freight,
        acceptance,
    },
    props: {},
    data() {
        return {
            releaseAlsoLook: true,
            loading: false,
            // 时间控件快捷选项
            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        picker.$emit('pick', new Date());
                    },
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', date);
                    },
                }, {
                    text: '一周前',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', date);
                    },
                }],
            },
            // 历史期数集合
            history: [],
            // 页面初始化数据
            initData: {},
            // 是否已发布,0未发布，1已发布
            isRelease: 1,
            // 默认显示升降标号页
            currentView: 'grade',
            // 当前选中tab
            tabActive: 0,
            // tab数据
            tabData: [
                {
                    name: '升降标号',
                    id: 'grade',
                    className: 'iconshengjiang',
                },
                {
                    name: '附加型号',
                    id: 'additional',
                    className: 'iconadd',
                },
                {
                    name: '泵送费',
                    id: 'pumpingCost',
                    className: 'iconfeiyong',
                },
                {
                    name: '运费',
                    id: 'freight',
                    className: 'iconzhangdanBill',
                },
                // {
                //     name: '承兑',
                //     id: 'acceptance',
                //     className: 'iconchengdui',
                // },
            ],
            // 当前期数ID
            historyId: '',
            // 区域ID
            areaid: 0,
            // 是否选择区域
            isRegion: false,
            // 区域列表数据
            regionData: [],
            // tab传入页面数据(默认显示升降标号数据)
            tabPageData: [],
            // 生成新期数按钮状态
            buttonLoading: false,
            // 生效时间
            timeValue: '',
            selectRow: {},
        };
    },
    mounted() {
        // 获取历史期数
        setTimeout(() => { this.getHistory(); }, 20);
    },
    methods: {
        // 获取历史期数
        getHistory() {
            this.$axios
                .get('/interfaceApi/sale/price_config/get_history')
                .then(res => {
                    if (res.length > 0) {
                        // 历史期数集合
                        this.history = res;
                        // 当前期数ID
                        this.historyId = res[0].id;
                        // 拉取期数初始化信息
                        this.getPriceInit();
                    }

                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 拉取期数初始化信息
        getPriceInit() {
            this.loading = true;
            this.$axios
                .get('/interfaceApi/sale/price_config/get_price_init/' + this.historyId)
                .then(res => {
                    if (res !== '') {
                        // 生效时间
                        this.timeValue = res.SalePriceInfo.apply_time;
                        // 页面初始化数据赋值
                        this.initData = res;

                        // 当前期数是否是历史期数
                        if (this.historyId !== this.history[0].id) {
                            this.isRelease = 2;
                        } else {
                        // 当前期数是否已发布
                            this.isRelease = res.SalePriceInfo.status;
                        }
                        // 是否按区域
                        this.isRegion = res.SalePriceInfo.is_area;
                        if (this.isRegion) {
                            this.selectRow = res.AreaList[0];
                            // 获取区域列表数据
                            this.getArea();
                        }
                        if (res.AreaList !== null && res.AreaList !== 'null') {
                            this.areaid = res.AreaList[0].area_id;
                            this.regionData = res.AreaList;
                        } else {
                            this.regionData = [];
                        }
                        // tab传入页面数据
                        if (res.LabelList.length > 0) {
                            this.tabPageData = res.LabelList;
                        }
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 选择期数刷新数据
        selectionPeriod() {
            this.getPriceInit();
        },
        // tab切换
        tabChange(tabItem, index) {
            // 切换展开组件
            // setTimeout(() => {
            this.currentView = tabItem;
            // }, 100);
            // tab按钮选中状态
            if (typeof index !== 'undefined' || index !== 'null') {
                this.tabActive = index;
            }
        },
        // 选择区域
        selectionArea() {
            // isRelease = 0  未发布
            if (this.isRelease === 0) {
                this.isRegion = !this.isRegion;
                this.preserveBasicInfo('isArea');
            } else if (this.isRelease !== 0) {
                this.isRegion = !this.isRegion;
                if (!this.isRegion) {
                    this.regionData = [];
                    this.isRegion = false;
                    this.areaid = 0;
                } else {
                    this.getArea();
                }
            }

        },
        // 返回最新一期
        returnLatest() {
            // 赋值最新一期id
            this.historyId = this.history[0].id;
            // 拉取期数初始化信息
            this.getPriceInit();
        },
        // 选择销售区域区域回调
        selectionAreaCallback(areaid, row) {
            this.selectRow = row;
            // 选择区域回调赋值区域ID
            this.areaid = areaid;
            // 选择区域获得区域ID，刷新tab页数据
            this.tabChange(this.currentView, this.tabActive);
        },
        // 区域弹出层
        regionalManagement() {
            this.$toast({
                width: '15.4rem',
                t_url: 'salesBusiness/priceSetting/regionalManagement',
                extr: {
                    closeCallback: this.hideAlert,
                    code: {
                        TableCode: 'yx001',
                    },
                },
            });
        },
        hideAlert() {
            // 获取历史期数
            this.getHistory();
        },
        // 获取区域列表数据
        getArea() {
            this.$axios
                .get('/interfaceApi/sale/price_config/get_price_area/' + this.historyId)
                .then(res => {
                    // 区域列表数据
                    this.regionData = res;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 泵送费页面回调
        pumpingCostCallback(pumpheight, pumpprice) {
            this.initData.SalePriceInfo.pump_height = pumpheight;
            this.initData.SalePriceInfo.pump_price = pumpprice;
            this.preserveBasicInfo();
        },
        modifyPrice(data) {
            if (!this.isRegion) {
                this.initData.SalePriceInfo.is_pump_base = data.is_pump_base;
                this.initData.SalePriceInfo.pump_base = data.pump_base;
                this.initData.SalePriceInfo.pump_height = data.pump_height;
                this.initData.SalePriceInfo.pump_price = data.pump_price;
                this.initData.SalePriceInfo.pump_min = data.pump_min;
                this.initData.SalePriceInfo.pump_add = data.pump_add;
                this.preserveBasicInfo();
            } else {
                this.preserveBasicInfoArae(data);
            }
        },
        preserveBasicInfoArae(data) {
            this.$axios
                .put('/interfaceApi/sale/price_config/modify_price_area', data)
                .then(res => {

                    if (res) {
                        this.$message({
                            message: res,
                            type: 'success',
                        });
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存基础信息
        preserveBasicInfo(type) {
            this.loading = true;
            if (type === 'timeValue') {
                this.initData.SalePriceInfo.apply_time = this.timeValue;
            }
            if (type === 'isArea') {
                this.initData.SalePriceInfo.is_area = this.isRegion;
            }
            const gitData = this.initData.SalePriceInfo;
            this.$axios
                .put('/interfaceApi/sale/price_config/modify_price', gitData)
                .then(res => {
                    if (res) {
                        this.$message({
                            message: res,
                            type: 'success',
                        });
                        if (type === 'isArea') {
                            if (this.isRegion) {
                                // 获取区域列表数据
                                this.getArea();
                            }
                            // 拉取期数初始化信息
                            this.getPriceInit();
                        } else {
                            this.loading = false;
                        }
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 发布版本
        release() {
            this.$axios
                .put('/interfaceApi/sale/price_config/publish/' + this.historyId)
                .then(res => {
                    if (res) {
                        this.$message({
                            message: res,
                            type: 'success',
                        });
                        this.isRelease = 1;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 生成新期数
        generateNewPeriods() {
            this.loading = true;
            this.buttonLoading = true;
            const _that = this;
            this.$axios
                .post('/interfaceApi/sale/price_config/create', this.initData)
                .then(res => {
                    _that.getHistory();
                    _that.initData = res;
                    // 生成新期数，修改按钮状态
                    _that.isRelease = 0;
                    _that.buttonLoading = false;
                    _that.loading = false;
                })
                .catch(error => {
                    _that.$message.error(error);
                });
        },
    },
    created() { },
};
</script>
<style lang="stylus">
.Sale
    .selectionArea
        position relative;
        .isSelectionArea
            position absolute
            width 100%
            height 100% !important
            top: 0rem;
            left 0
            z-index 9
            background rgba(0,0,0,0.2)
    .basePrice_l
        position relative
        .isReleaseMask
            position absolute
            width 100%
            height 100% !important
            top: .39rem;
            left 0
            z-index 9
            background rgba(0,0,0,0.2)
    .historicalPeriodMask
        position absolute
        width 100%
        height 100% !important
        top 0
        left 0
        z-index 9
        background rgba(0,0,0,0.2)
    .header_Sale
        width 100%
        height 0.8rem
        background #fff
        >span
            font-size 0.16rem
            line-height 0.8rem
            padding 0 0.2rem
        .el-date-editor,
        .el-button,
        .el-select
            margin 0.2rem 0.6rem 0.2rem 0
        .el-date-editor
            input
                height 0.4rem
                line-height 0.4rem
            .el-input__icon
                width 0.25rem
                line-height 0.4rem
        .el-button
            padding 0
            height 0.4rem
            padding 0 0.2rem
        .el-select
            .el-input__inner
                height 0.4rem
                line-height 0.4rem
            .el-input__icon
                width 0.25rem
                line-height 0.4rem
    .tables
        position relative
</style>